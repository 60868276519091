/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

.App {
  width: 100%;
}

.tixbcHeader {
  float: left;
  width: 100%;
  padding: 12px 15px;
  left: 0px;
  top: 0px;
  z-index: 9999;
  /*height: 150px;*/
  /*float: left;*/
}

.tixbcBackIcon {
  float: left;
  width: 24px;
  text-align: center;
  -webkit-text-size-adjust: 100%;
  --speechify-primary-color: #4856fd;
  --font-family: Inter, sans-serif;
  --transitions: none;
  --color-primary: #06b;
  --color-secondary: #011645;
  --color-tm: #026cdf;
  --color-text-primary: #fff;
  --color-text-secondary: #000;
  --menuText-color: #fff;
  color: #333;
  font-weight: 300;
  line-height: 1.5em;
  font-family: "Averta" !important;
  font-size: 1.4rem;
  scroll-behavior: auto !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  float: left;
  width: 24px;
  text-align: center;
  /*width: 15px; height: 15px;*/
  /*position: relative;*/
}

.tixbcBackIconSVG {
  -webkit-text-size-adjust: 100%;
  --speechify-primary-color: #4856fd;
  --font-family: Inter, sans-serif;
  --transitions: none;
  --color-primary: #06b;
  --color-secondary: #011645;
  --color-tm: #026cdf;
  --color-text-primary: #fff;
  --color-text-secondary: #000;
  --menuText-color: #fff;
  scroll-behavior: auto !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  color: rgb(255, 255, 255);
  width: 15px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}

.tixbcEventHeader {
  -webkit-text-size-adjust: 100%;
  --speechify-primary-color: #4856fd;
  --font-family: Inter, sans-serif;
  --transitions: none;
  --color-primary: #06b;
  --color-secondary: #011645;
  --color-tm: #026cdf;
  --color-text-primary: #fff;
  --color-text-secondary: #000;
  --menuText-color: #fff;
  color: #333;
  font-weight: 300;
  line-height: 1.5em;
  font-family: "Averta" !important;
  font-size: 1.4rem;
  scroll-behavior: auto !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  float: right;
  width: calc(100% - 40px);
}

.slide {
  width: 100%;
  display: inline-block;
}

.tixbcTest {
  -webkit-text-size-adjust: 100%;
  --speechify-primary-color: #4856fd;
  --font-family: Inter, sans-serif;
  --transitions: none;
  --color-primary: #06b;
  --color-secondary: #011645;
  --color-tm: #026cdf;
  --color-text-primary: #fff;
  --color-text-secondary: #000;
  --menuText-color: #fff;
  color: #333;
  font-weight: 300;
  line-height: 1.5em;
  font-family: "Averta" !important;
  font-size: 1.4rem;
  scroll-behavior: auto !important;
  direction: ltr;
  unicode-bidi: isolate;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  float: left;
  width: 100%;
  position: relative;
}

.tixbcEventName {
  --speechify-primary-color: #4856fd;
  --font-family: Inter, sans-serif;
  --transitions: none;
  --color-primary: #06b;
  --color-secondary: #011645;
  --color-tm: #026cdf;
  --color-text-primary: #fff;
  --color-text-secondary: #000;
  --menuText-color: #fff;
  scroll-behavior: auto !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  font-family: "Arial" !important;
  color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  float: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1px;
  margin-top: 0;
}

.tixbcEventLocationDate {
  --speechify-primary-color: #4856fd;
  --font-family: Inter, sans-serif;
  --transitions: none;
  --color-primary: #06b;
  --color-secondary: #011645;
  --color-tm: #026cdf;
  --color-text-primary: #fff;
  --color-text-secondary: #000;
  --menuText-color: #fff;
  font-family: "Arial" !important;
  scroll-behavior: auto !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  float: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.tixbcCarousel {
  /*display: none;*/
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  z-index: 0;
  width: 100%;
  background-color: white;
}

.carousel-indicators {
  display: none;
  visibility: hidden;
}

.tixbc_div_c {
  border: 2px solid black;
  /*border-radius: 25px;*/
  padding: 20px;
  height: 300px;
  /*position: fixed;*/
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  /*border-style: ;*/
}

.tixbcHeaderGameDetails {
  align: left;
  color: white;
  padding-left: 40px;
}
.tixbcHeaderGameDetailsGameName {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  float: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1px;
  margin-top: 0;
}
.tixbcHeaderGameDetailsLoc {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  float: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

html,
body {
  height: 100%;
  /*background-color: firebrick;*/
  /*margin: 0px;*/
}

.tixbcTicketBody {
  /*background-color: dimgrey;*/
  height: 100vh;
  width: 100%;
  /*margin-bottom: 0px;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  float: left;
}
.tixbcTicketBody1 {
  text-align: center;
  float: left;
  overflow: scroll;
  border-radius: 12px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: auto;
  padding-left: 10px;
  padding-right: 10px;
  height: 414px;
  /*width: 500px;*/
  /*background-color: yellowgreen;*/
}

.slick-slide {
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
}

#barcode-here {
  padding-top: 10px;
  align-items: center;
  marin: auto;
  /*background-color:yellowgreen;*/
  width: 216px;
}
.barcode-inner-container {
  margin: auto;
  width: 216px;
}

.ticketHeaderTop {
  color: white;
  background: rgba(0, 0, 0, 0.3);
}

.infoIcon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(500%)
    contrast(200%);
}

.ticketHeaderLower {
  display: flex;
  justify-content: space-between;
  padding: 16px 0 10px;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 10px;
}

.rowDetails {
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 33.3333%;
  display: block;
  flex: 0 0 33.3333%;
}

.tmdbFooter {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  padding: 16px;
  box-shadow: rgb(0 0 0 / 50%) 0px -2px 24px 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: rgb(255, 255, 255);
}

#transferButton {
  transform: none;
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
  opacity: 0.2;
  margin: 3px;
  width: 30%;
}

#sellButton {
  transform: none;
  width: auto;
  margin: 3px;
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
  width: 30%;
  opacity: 0.2;
}
.meatball-button {
  max-width: 46px;
  min-width: 46px;
  padding: 0px;
  width: 46px !important;
  position: relative !important;
}
.meatball-in-carousal {
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgb(78, 56, 126);
  border-radius: 50%;
  margin: auto;
}

.meatballbox {
  padding: 10px;
  color: white;
}
